<template>
  <div>
    <div class="card">
      <van-steps :active="active" active-icon="success" active-color="#38f">
        <van-step>住院信息</van-step>
        <van-step>证件照片</van-step>
        <van-step>获取方式</van-step>
        <van-step>确认信息</van-step>
      </van-steps>
    </div>
    <div class="card">
      <van-form ref="vanForm" v-model="formData" @submit="onSubmit">
        <div v-if="active === 0">
          <van-field
            name="sex"
            label="与患者关系"
            required
            placeholder="请选择代理人与患者关系"
          >
            <template #input>
              <van-radio-group
                v-model="formData.relationID"
                direction="horizontal"
                @change="changeRelationID"
              >
                <van-radio
                  v-for="(item, index) in relationOptions"
                  :key="index"
                  :name="item.value"
                >
                  {{ item.label }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="formData.name"
            name="name"
            label="患者姓名"
            placeholder="请填写患者姓名"
            :rules="[{ required: true, message: '' }]"
            required
          />
          <van-field
            v-model="formData.cardNo"
            name="cardNo"
            label="身份证号码"
            placeholder="请输入身份证号码"
            :rules="[
              { required: true, message: '' },
              { validator, message: '证件号码格式错误！' },
            ]"
            required
          />
          <van-field
            v-model="formData.hospitalNumber"
            name="hospitalNumber"
            label="住院号"
            placeholder="不知道可以不填写"
          />
          <van-field
            readonly
            clickable
            :value="formData.ryTime"
            name="ryTime"
            label="入院时间"
            placeholder="点击选择入院时间"
            :rules="[{ required: true, message: '' }]"
            required
            @click="showRyPicker = true"
          />
          <van-field
            readonly
            clickable
            name="cyTime"
            :value="formData.cyTime"
            label="出院时间"
            placeholder="点击选择出院时间"
            :rules="[{ required: true, message: '' }]"
            required
            @click="showCyPicker = true"
          />
          <van-popup v-model="showRyPicker" position="bottom">
            <van-datetime-picker
              v-model="date"
              title="入院时间"
              type="date"
              :min-date="minDate"
              @confirm="(time) => onConfirm(time, 'ryTime', 'showRyPicker')"
              @cancel="showRyPicker = false"
            />
          </van-popup>
          <van-popup v-model="showCyPicker" position="bottom">
            <van-datetime-picker
              v-model="date"
              title="出院时间"
              :min-date="minDate"
              type="date"
              @confirm="(time) => onConfirm(time, 'cyTime', 'showCyPicker')"
              @cancel="showCyPicker = false"
            />
          </van-popup>
          <van-field
            v-model="formData.deptName"
            name="deptName"
            label="科室"
            placeholder="不知道可以不填写"
          />
          <van-field
            v-model="formData.mobile"
            name="mobile"
            label="手机号码"
            required
            placeholder="请输入手机号码"
            :rules="[
              { required: true, message: '' },
              {
                validator: (value) => {
                  return /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/.test(
                    value
                  )
                },
                message: '请输入正确格式的手机号码',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div v-show="active === 1">
          <van-field
            v-if="formData.relationID === 2"
            name="sex"
            label="监护人证明"
            required
            placeholder=""
          >
            <template #input>
              <van-radio-group
                v-model="formData.paperType"
                direction="horizontal"
                @change="changePaperType"
              >
                <van-radio
                  v-for="(item, index) in paperTypeOptions"
                  :key="index"
                  :name="item.value"
                >
                  {{ item.label }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-notice-bar
            v-if="formData.relationID === 2"
            wrapable
            :scrollable="false"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;出生证明上传一张照片即可。户口本则需上传户口本监护人页与户口本患者页。
          </van-notice-bar>
          <van-row class="picList" style="text-align: center">
            <van-col v-for="item in imageList" :key="item.sort" :span="24">
              <div v-if="item.des !== ''">
                <van-row>
                  <div
                    style="padding: 10px;background: white;box-sizing: content-box; text-align: center"
                  >
                    <UploadFile
                      ref="upload"
                      :img-info="item"
                      :preview-size="146"
                      @fetch-data="
                        (pathList) => fetchImage(item.sort, pathList)
                      "
                    />
                  </div>
                </van-row>
                <van-row class="des">{{ item.des }}</van-row>
              </div>
              <div v-else style="height: 216px">&nbsp;</div>
            </van-col>
          </van-row>
        </div>
        <div v-if="active === 2">
          <van-field
            v-model="formData.name"
            name="name"
            label="收货人"
            placeholder="请输入收货人"
            required
            disabled
          />
          <van-field
            v-model="formData.mobile"
            name="mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话"
            disabled
          />
          <van-field
            readonly
            clickable
            :value="formData.address"
            name="address"
            label="地址"
            placeholder="点击选择地址"
            :rules="[{ required: true, message: '' }]"
            required
            @click="showAreaPicker = true"
          />
          <van-popup v-model="showAreaPicker" position="bottom">
            <van-area
              title="省市区"
              :area-list="areaList"
              :columns-placeholder="['请选择', '请选择', '请选择']"
              @confirm="areaConfirm"
            />
          </van-popup>
          <van-field
            v-model="formData.addressInfo"
            name="addressInfo"
            label="详细地址"
            placeholder="请填写详细地址"
            :rules="[{ required: true, message: '' }]"
            required
          />
          <van-field name="copyCount" label="打印份数" placeholder="" required>
            <template #input>
              <van-stepper
                v-model="formData.copyCount"
                theme="round"
                step="1"
                min="1"
                max="10"
                disable-input
              />
            </template>
          </van-field>
          <van-field
            readonly
            clickable
            name="copyUse"
            label="复印用途"
            :value="formData.copyUseText"
            required
            placeholder="选择复印用途"
            :rules="[{ required: true, message: '' }]"
            is-link
            @click="showCopyUsePicker = true"
          />
          <van-popup v-model="showCopyUsePicker" round position="bottom">
            <van-picker
              title="复印用途"
              show-toolbar
              :columns="copyUseOptions"
              value-key="label"
              :colunms-field-names="{ text: 'label' }"
              @cancel="showCopyUsePicker = false"
              @confirm="copyUseConfirm"
            />
          </van-popup>
          <van-field
            readonly
            name="copyContent"
            label="复印内容"
            :value="formData.copyContent"
            placeholder="选择复印内容"
            required
            :rules="[{ required: true, message: '请选择复印内容' }]"
          >
            <template #input>
              <van-checkbox-group ref="checkboxGroup" v-model="copyContent">
                <van-checkbox
                  v-for="item in copyContentOptions"
                  :key="item.id"
                  shape="square"
                  :name="item.id"
                >
                  {{ item.name }}
                  <br />
                </van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
        </div>
      </van-form>
    </div>
    <div v-if="active === 3">
      <div class="card">
        <van-notice-bar wrapable :scrollable="false">
          &nbsp;&nbsp;&nbsp;&nbsp;请您认真核对以下信息，确认所有信息准确无误后方可提交，提交申请后不可在修改，若发现有错误，可点击上一步返回上级修改信息。
        </van-notice-bar>
      </div>
      <div class="card">
        <div class="title">
          住院信息
        </div>
        <div class="list">
          <div class="grid-table">
            <div>医院</div>
            <div>余姚市妇幼保健院</div>
            <div>患者姓名</div>
            <div>{{ formData.name }}</div>
            <div>身份证号码</div>
            <div>{{ formData.cardNo }}</div>
            <div>住院号</div>
            <div>{{ formData.hospitalNumber }}</div>
            <div>入院时间</div>
            <div>{{ formData.ryTime }}</div>
            <div>出院时间</div>
            <div>{{ formData.cyTime }}</div>
            <div>住院科室</div>
            <div>{{ formData.deptName }}</div>
            <div>手机号</div>
            <div>{{ formData.mobile }}</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="title">
          证件照片
        </div>
        <van-row class="picList" style="text-align: center">
          <van-col v-for="(item, i) in imageList" :key="i" :span="24">
            <div
              v-if="item.des != ''"
              style="padding: 10px;background: white;box-sizing: content-box"
              @click="imagePreview(item.url)"
            >
              <van-row>
                <van-image
                  v-for="(url, j) in item.url"
                  :key="j"
                  width="100%"
                  height="150"
                  fit="contain"
                  :src="flag ? 'api/' + url : url"
                />
                <van-row class="des">{{ item.des }}</van-row>
              </van-row>
            </div>
            <div v-else style="height: 216px">&nbsp;</div>
          </van-col>
        </van-row>
      </div>
      <div class="card">
        <div class="title">
          复印和快递信息
        </div>
        <div class="list">
          <div class="grid-table">
            <div>收货地址</div>
            <div>{{ formData.address }}&nbsp;{{ formData.addressInfo }}</div>
            <div>复印份数</div>
            <div>{{ formData.copyCount }}份</div>
            <div>复印内容</div>
            <div style="white-space: pre-line;">
              {{ formData.copyContentText }}
            </div>
            <div>费用</div>
            <div>{{ formData.copyFee }}元</div>
            <div>快递名称</div>
            <div>{{ formData.expressName }}</div>
            <div>快递单号</div>
            <div>{{ formData.expressNumber }}</div>
            <div>支付状态</div>
            <div>{{ formData.payStateText }}</div>
            <div>支付时间</div>
            <div>{{ formData.payTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-row>
      <van-col v-if="active === 0" :span="24">
        <van-button type="info" round @click="step(1)">
          <div>下一步</div>
        </van-button>
      </van-col>
      <van-col v-if="active > 0 && active < 4" :span="12">
        <van-button type="warning" round @click="step(0)">
          <div>上一步</div>
        </van-button>
      </van-col>
      <van-col v-if="active > 0 && active < 3" :span="12">
        <van-button type="info" round @click="step(1)">
          <div>下一步</div>
        </van-button>
      </van-col>
      <van-col v-if="active === 3" :span="12">
        <van-button type="info" round @click="onSubmit">
          <div>提交</div>
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import { ImagePreview, Notify } from 'vant'
  import { areaList } from '@vant/area-data'
  import { Toast } from 'vant'
  import { Dialog } from 'vant'
  import UploadFile from '@/components/UploadFile'
  import { getPersonInfo } from '@/util/validate'
  import { getOption } from '@/api/com/dic/dic'
  import { getSysCaseList } from '@/api/sys/sysCase'
  import { doAdd, getCopyFee } from '@/api/case/caseCopy'
  import Base64 from '@/util/Base64'

  export default {
    name: 'CaseCopyAdd',
    components: { UploadFile },
    data() {
      return {
        date: new Date(moment(Date.now()).format('YYYY-MM-DD')),
        minDate: new Date(1998, 0, 1),
        //是否生产环境
        flag: null,
        //省市区信息
        areaList,
        active: 0,
        //选择器展示
        showRyPicker: false,
        showCyPicker: false,
        showAreaPicker: false,
        showCopyUsePicker: false,
        copyContent: [],
        formData: {
          id: 0,
          openID: '',
          name: '',
          cardNo: '',
          hospitalNumber: '', //住院号
          ryTime: '',
          cyTime: '',
          deptName: null, //科室
          mobile: '',
          relationID: 1, //1本人 2代理人
          paperType: 1, //1出生证明 2户口本
          createTime: this.date,
          status: 0,
          reamrk: '',
          expressName: '',
          expressNumber: '',
          imageUrl: '',
          address: '',
          addressInfo: '',
          copyUse: null, //复印用途
          copyUseText: null, //复印用途text
          copyContent: '', //复印内容
          copyContentText: '',
          copyCount: 1,
          copyFee: 0,
          imageList: [],
          payState: 0,
          payStateText: '待支付',
        },

        imageList: [],
        //本人所需照片材料
        selfImageList: [
          {
            des: '身份证人像面照片',
            url: [],
            multiple: 1,
            background: require('@/assets/caseCopy_images/cardFront.png'),
            sort: 1,
          },
          {
            des: '身份证国徽面照片',
            url: [],
            multiple: 1,
            background: require('@/assets/caseCopy_images/cardBack.png'),
            sort: 2,
          },
        ],
        //监护人所需照片材料
        guardianList: [
          {
            des: '监护人身份证人像面照片',
            url: [],
            multiple: 1,
            background: require('@/assets/caseCopy_images/cardFront.png'),
            sort: 1,
          },
          {
            des: '监护人身份证国徽面照片',
            url: [],
            multiple: 1,
            background: require('@/assets/caseCopy_images/cardBack.png'),
            sort: 2,
          },
          {
            des: '出生证明照片',
            url: [],
            multiple: 2,
            background: require('@/assets/caseCopy_images/paper.png'),
            sort: 3,
          },
        ],
        copyUseOptions: [],
        relationOptions: [],
        copyContentOptions: [],
        paperTypeOptions: [
          { label: '出生证明', value: 1 },
          { label: '户口本', value: 2 },
        ],
      }
    },
    async created() {
      this.flag = process.env.NODE_ENV === 'production'
      await this.getOption('copyUseOptions', 4)
      await this.getOption('relationOptions', 5)
      await this.getCopyContentOption()
      this.formData.openID = store.getters['user/openId']
    },
    methods: {
      getPersonInfo,
      //日期回调
      onConfirm(date, type, picker) {
        this.formData[type] = moment(date).format('YYYY-MM-DD')
        if (
          this.formData.cyTime < this.formData.ryTime &&
          this.formData.cyTime != '' &&
          this.formData.ryTime != ''
        )
          Dialog.alert({
            message: '出院时间不能小于入院时间',
          }).then(() => {
            this.formData[type] = ''
          })
        this[picker] = false
      },
      //与患者关系改变
      changeRelationID() {
        this.imageList = []
      },
      //监护人证明选择
      changePaperType(value) {
        this.imageList[2].url = []
        switch (value) {
          case 1:
            this.imageList[2].des = '出生证明照片'
            this.imageList[2].multiple = 1
            break
          case 2:
            this.imageList[2].des = '户口本监护人页与患者页照片'
            this.imageList[2].multiple = 2
            break
        }
        this.$nextTick(() => {
          this.$refs.upload[2].render(this.imageList[2])
        })
      },
      //地区选择回调
      areaConfirm(address) {
        this.formData.address =
          address[0].name + '|' + address[1].name + '|' + address[2].name
        this.showAreaPicker = false
      },
      //复印用途选择回调
      copyUseConfirm(object) {
        this.formData.copyUse = object.value
        this.formData.copyUseText = object.label
        this.showCopyUsePicker = false
      },

      step(type) {
        //返回上一步不用输入验证
        if (type === 0) this.active--
        else {
          //照片必填
          if (this.active === 1) {
            for (let i = 0; i < this.imageList.length; i++) {
              if (this.imageList[i].url.length < this.imageList[i].multiple) {
                Dialog.alert({
                  message: '请将照片信息填写完整',
                }).then()
                return
              }
            }
          }
          //输入验证通过进入下一页
          this.$refs['vanForm'].validate().then(() => {
            this.getAge()
            this.active++
            //照片上传，初始化
            switch (this.active) {
              case 1:
                switch (this.formData.relationID) {
                  case 1:
                    this.imageList = this.selfImageList
                    break
                  case 2:
                    this.imageList = this.guardianList
                    this.changePaperType(this.formData.paperType)
                    break
                }
                break
              case 3:
                this.formData.copyContentText = ''
                this.copyContent.forEach((id) => {
                  this.copyContentOptions.forEach((item) => {
                    if (item.id === id)
                      this.formData.copyContentText += item.name + ','
                  })
                })
                this.getCopyFee()
                break
            }
          })
        }
      },

      //跳转列表
      toList() {
        this.$router.replace({
          path: '/caseCopy',
        })
      },

      //身份证验证
      validator(val) {
        const card15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/
        const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        if (card15.test(val) || card18.test(val)) this.getAge()
        return card15.test(val) || card18.test(val)
      },

      //身份证计算年龄
      getAge() {
        let { age } = getPersonInfo(this.formData.cardNo)
        if (age < 18 && this.formData.relationID !== 2)
          Dialog.alert({
            message: '未满十八周岁需要监护人代为申请',
          }).then(() => {
            this.formData.relationID = 2
          })
      },

      //打印费用
      async getCopyFee() {
        let copyContentStr = this.copyContent.join('|')
        const { data } = await getCopyFee({
          CopyContentStr: copyContentStr,
          CopyCount: this.formData.copyCount,
        })
        this.formData.copyFee = data
      },

      //照片预览
      imagePreview(urls) {
        let [...imageUrls] = urls
        if (this.flag)
          for (let i = 0; i < imageUrls.length; i++)
            imageUrls[i] = 'api/' + imageUrls[i]
        ImagePreview({
          images: imageUrls,
        })
      },

      async onSubmit() {
        Dialog.confirm({
          title: '提交确认',
          message: '将保存的信息提交至系统',
        })
          .then(async () => {
            this.formData.copyContent = this.copyContent.join('|')
            //照片
            this.formData.imageList = this.imageList
            Toast.loading({
              duration: 0, // 持续展示 toast
              message: '正在提交中...',
              forbidClick: true,
            })
            const { success, msg } = await doAdd(this.formData)
            Toast.clear()
            let type = success ? 'success' : 'danger'
            Notify({ type: type, message: msg })
            if (success) this.toList()
          })
          .catch(() => {
            // on cancel
          })
      },
      //照片上传回调方法
      fetchImage(sort, pathList) {
        this.imageList[sort - 1].url = []
        pathList.forEach((item) => this.imageList[sort - 1].url.push(item.url))
      },

      //获取字典选项
      async getOption(name, typeID) {
        const { data } = await getOption({ typeID: typeID })
        for (let i = 0; i < data.options.length; i++)
          data.options[i].value = Number(data.options[i].value)
        this[name] = data.options
      },

      //获取打印内容
      async getCopyContentOption() {
        const { data } = await getSysCaseList({})
        this.copyContentOptions = data
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-button--info {
    width: 95%;
    margin-left: 2.5%;
  }
  .van-button--warning {
    width: 95%;
    margin-left: 2.5%;
  }
  .van-checkbox__label {
    margin-top: 10px;
  }
  .van-checkbox {
    margin-top: 15px;
  }
  ::v-deep .van-address-edit__buttons {
    display: none;
  }
  ::v-deep .van-stepper--round .van-stepper__plus {
    background-color: #1989fa;
  }
  ::v-deep .van-stepper--round .van-stepper__minus {
    border: 1px solid #1989fa;
    color: #1989fa;
  }
  /*::v-deep .van-step--horizontal .van-step__title {*/
  /*  font-weight: bold;*/
  /*  color: black;*/
  /*  font-size: 14px;*/
  /*}*/
  /*::v-deep .van-cell {*/
  /*  font-size: 18px;*/
  /*}*/
  .card {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .des {
      text-align: center;
    }
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
  .picList {
    display: grid;
    grid-template-columns: 50% 50%;
    .van-col {
    }
  }
</style>
